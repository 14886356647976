import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import { FaMobileAlt } from "react-icons/fa";
import Lottie from "lottie-react";

class PlayerTutorial extends React.Component {  
    render() {
        const { isVIP, onClick, style } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const playerWaitingForHost = require(`../assets/player-waiting.json`);

        return (
            <div style={style}>
                {(isVIP)? (
                <div>
                    <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        onClick();
                    }}>Skip Tutorial</a>
                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '16px'}}>Be sure your team understands how to play before you skip this important tutorial. You will not have an option to go back and review the rules once your game begins</p>
                </div>
                ) : (
                    <div>
                        <p style={{margin: '24px auto', textAlign: 'center', color: 'white'}}><b>TUTORIAL NOW PLAYING</b></p>
                        <Lottie style={{height: 'auto', width: 'auto'}} animationData={playerWaitingForHost} />
                    </div>
                )}
            </div>
        );
    }
}
  
export default PlayerTutorial;