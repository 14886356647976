import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import Lottie from "lottie-react";
import PlayerCard from "../resources/PlayerCard";
import ConfettiComponent from "../components/ConfettiComponent";

class PlayerPlaying extends React.Component {  
    render() {
        const { activeHost, isVIP, onClick, onEndGameClicked, onContinueClicked, skipPlayerClicked, style, isCurrentPlayer, cards } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        return (
            <div style={style}>
                {(activeHost?.currentState === "waitingForVIPToProceed")? (
                    <div>
                        <ConfettiComponent style={{height: '50vw'}} />
                        {(isVIP)? (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white'}}>You completed a project! Review it on the screen with your team and then tap 'Continue' to proceed to the next project!</p>
                                <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                                    onContinueClicked();
                                }}>Continue</a>
                            </div>
                        ) : (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white'}}>You completed a project! Review the cards with your team!</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        {(isCurrentPlayer)? (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white'}}><b>It's your turn!</b><br></br><br></br>Play a card by selecting it, and then hitting 'Play'</p>
                            </div>
                        ) : (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white'}}>Review your cards, your turn is coming up soon.</p>
                            </div>
                        )}
                    </div>
                )}
                {(activeHost?.currentState != "waitingForVIPToProceed")? (
                    <div className="card-collection" style={{width: '90%'}}>
                        {cards.map(card => (
                            <PlayerCard 
                            card={card.data()}
                            onClick={() => {
                                // selectNewCard(card.data());
                                onClick(card.data());
                            }} />
                        ))}
                    </div>
                ) : (
                    <div>

                    </div>
                )}
                {(isVIP && activeHost?.currentState != "waitingForVIPToProceed")? (
                <div>
                    <h3 style={{textAlign: "center", color: '#FFFFFF', margin: '16px'}}>VIP CONTROLS</h3>
                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '16px'}}>Is a team member taking too long? Are they in the bathroom? Press this button to keep the game going and skip to the next player!</p>
                    <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        skipPlayerClicked();
                    }}>Skip Current Player</a>

                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '64px 16px 16px 16px'}}>Be sure your game is over before pressing this button!</p>
                    <a className="final-submit-btn red-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        onEndGameClicked();
                    }}>End Game</a>
                    
                </div>
                ) : (
                    <div></div>
                )}
            </div>
        );
    }
}
  
export default PlayerPlaying;