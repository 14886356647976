import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Account from "./account/Account";
import Navbar from './components/Navbar';
import Resources from './resources/Resources';
import Tutorial from './tutorial/Tutorial';
import Footer from './components/Footer/Footer';
import Upgrade from './Upgrade/Upgrade';
import MyGames from './create/MyGames';
import Host from './host/Host';
import Player from './player/Player';
import Pricing from "./pricing/Pricing";

function App() {
  return (
    <div className="app" style={{background: '#333333'}}>
      <Router>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/my-games" element={<MyGames />} />
          <Route exact path="/get-started" element={<Tutorial />} />
          <Route exact path="/upgrade" element={<Upgrade />} />
          <Route exact path="/host" element={<Host />} />
          <Route exact path="/player" element={<Player />} />
          <Route exact path="/pricing" element={<Pricing />} />
        </Routes>
        
      </Router>
      <Footer/>
    </div>
  );
}
export default App;
