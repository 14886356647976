import React, { useRef, useEffect, useState } from "react";
import "./Resources.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useSprings, animated, to } from 'react-spring'
import LockOverlay from "./LockOverlay";

class ProTalkCard extends React.Component {  
  render() {
    const { card, isOverlayed, slotNumber, onClick } = this.props;
    
    // Colors
    const red = "#C65447";
    const redBackground = "#F4DDDA";
    const green = "#509E6F";
    const greenBackground = "#DCECE2";
    const blue = "#1961A8";
    const blueBackground = "#D1DFEE";
    const yellow = "#FFC337";
    const orange = "#ED6742";
    const gold = "#F0B52C";
    const goldBackground = "#FCF0D5";

    function getCardType() {
      if (card?.id < 37) {
          return "CHALLENGE";
      } else {
          return "SOLUTION";
      }
    };

    function getCardColor() {
      if (card?.id < 37) {
          return red;
      } else {
          return green;
      }
    }; 

    return (
      <div>
      {(card != null)? (
        <div className="card" style={{border: "10px", borderColor: `${getCardColor()}`, borderStyle: "solid", height: '300px', display: 'grid', marginRight: `${isOverlayed ? '64px': ''}`, left: `${isOverlayed ? '0px': ''}`, top: `${isOverlayed ? '20px': ''}`, rotate: `${isOverlayed ? '10deg': ''}`}} onClick={onClick}>
          <p className="card-title" style={{color: `${getCardColor()}`}}>{getCardType()}</p>
          <p className="card-name">{card.name}</p>
          <img id="cardimg" style={{paddingLeft: "20px", paddingRight: "20px", objectFit: "contain", width: "80%", height: "120px"}} src={require(`../assets/images/${card.id}.png`)} />
          <p style={{fontSize: "14px", textAlign: "center", height: "auto", marginTop: "auto", paddingLeft: "4px", paddingRight: "4px", color: "gray"}}>{card.detail}</p>
        </div>
      ) : (
        <div className="card" style={{border: "3px", borderColor: `#cccccc`, borderStyle: "dashed", height: '300px', display: 'grid', marginRight: `${isOverlayed ? '64px': ''}`, left: `${isOverlayed ? '0px': ''}`, top: `${isOverlayed ? '20px': ''}`, rotate: `${isOverlayed ? '10deg': ''}`}} onClick={onClick}>
          <p className="card-name" style={{margin: 'auto', color: "#888888"}}>{`SLOT ${slotNumber ?? 0}`}</p>
          <p className="card-name" style={{margin: '0 auto', color: "#cccccc"}}>PLAY A CARD</p>
      </div>
      )}
      </div>
    );
  }
}
  
export default ProTalkCard;