import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, doc, updateDoc } from "firebase/firestore";
import Cookies from 'universal-cookie';

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";
  const pink = "#DA2185";
  const purple = "#64509E";
  const colors = [blue, red, yellow, green, orange, "#000000", pink, purple];
  const avatars = ["46", "53", "37", "5", "38", "59", "60", "26"];

  const [hasGold, setGold] = useState(false);

  const attemptToJoinRoom = async () => {
    if (name == "" || code == "") {
      alert("Please enter a room code and name to continue");
      return;
    }
    // Check db for roomCode
    const upperCaseCode = code?.toUpperCase();
    console.log(`ROOM CODE: ${upperCaseCode}`);
    const q = query(collection(db, "hosts"), where("code", "==", `${upperCaseCode}`), where("active", "==", true));
    const potentialDocs = await getDocs(q);
    console.log(`DOCS: ${potentialDocs.docs}`);
    const foundRoom = potentialDocs.docs[0];
    // Room Exists
    if (foundRoom != null) {
      const roomData = foundRoom.data()
      const playerCount = roomData.players?.length ?? 0
      if (playerCount >= 8) {
        alert("Room is full!");
        return;
      }
      console.log(`ROOM KEY: ${foundRoom.id}`);
      console.log(`FOUND ROOM: ${roomData}`);
      console.log(`PLAYERS: ${roomData.players}`);
      console.log(`PLAYERS COUNT: ${playerCount}`);
      
      // CHECK IF COOKIE EQUALS A PLAYER IN THE ROOM
      if (cookies.get('roomCode') == code && cookies.get('myId') != null) {
        const didFindPlayer = roomData.players?.filter((player) => {
          return player.id == cookies.get('myId');
        }) ?? [];
        if (didFindPlayer.length > 0) {
          console.log('FOUND PLAYER MATCHING COOKIE')
          navigate('/player');
          return;
        } else {
          cookies.remove('roomCode');
          cookies.remove('myName');
          cookies.remove('myId');
        }
      }

      const newPlayerId = playerCount;
      const newPlayerAvatar = avatars[playerCount];
      const newPlayerColor = colors[playerCount];
      const newPlayer = {
        id: newPlayerId,
        name: `${name.toUpperCase()}`,
        isVIP: (playerCount) == 0 ? true : false,
        color: newPlayerColor,
        avatar: `${newPlayerAvatar}`
      };
      if (playerCount == 0) {
        roomData.players = [newPlayer];
      } else {
        roomData.players.push(newPlayer);
      }
      // Insert new user into room
      const roomRef = doc(db, "hosts", foundRoom.id);
      await updateDoc(roomRef, {
        players: roomData.players
      });
      cookies.set('roomCode', code, { path: '/' });
      cookies.set('myName', name, { path: '/' });
      cookies.set('myId', newPlayerId, { path: '/' });
      navigate('/player');
    } else {
      alert("Unable to find your room, please try again.");
      cookies.remove('roomCode');
      cookies.remove('myName');
      cookies.remove('myId');
      setCode('')
      return;
    }
  }

  const fetchGoldStatus = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setGold(data.hasGold);
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchGoldStatus();
    setName(cookies.get('myName'));
    console.log(name);
    setCode(cookies.get('roomCode'));
    console.log(code);
  }, [user, loading]);
  return (
    <div className="full-page-container" style={{overflow: 'visible', minHeight: '1400px'}}>
      <div style={{margin: '48px auto', maxWidth: '600px'}}>
        <p className="score-text" style={{fontWeight: 700, color: '#ffffff'}}>ROOM CODE</p>
        <input className="room-code-input" id="roomcode" type="text" autocapitalize="off" autocorrect="off" autocomplete="off" placeholder="ENTER 4-LETTER CODE" maxlength="4" onChange={(e) => { 
          setCode(e.target.value);
        }} value={code}/>
        <p className="score-text" style={{fontWeight: 700, color: '#ffffff'}}>NAME</p>
        <input className="room-code-input" id="playername" type="text" autocapitalize="off" autocorrect="off" autocomplete="off" placeholder="ENTER NAME" maxlength="12" onChange={(e) => { 
          setName(e.target.value);
        }} value={name}/>
        <div style={{}}>
          <a className="final-submit-btn blue-btn-dash" style={{margin: '16px auto 48px auto'}} onClick={() => {
            attemptToJoinRoom();
          }}>JOIN</a>
        </div>
        {/* <p style={{color: '#d7d7d7'}}>Don't have a room to join? Start one for your team!</p>
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn green-btn-dash" style={{margin: '16px auto auto auto'}} href="/my-games">Start Game</a>
        </div> */}
      </div>
      <div style={{marginTop: '80px', display: 'flex', alignItems: 'center'}}>
        <div>
          <h4 className="dashboard-tag-left">GET STARTED</h4>
          <h1 className="dashboard-section-title" style={{fontSize: '50px', textAlign: 'left'}}>LET'S <span style={{color: '#268fff'}}>P</span><span style={{color: red}}>L</span><span style={{color: yellow}}>A</span><span style={{color: green}}>Y</span>!</h1>
          <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Grab a device to host, and make sure each player has a device of their own! It is easy to play in just 10 minutes. Playing Pro Talk virtually requires a Pro Talk Plus plan.</p>
        </div>
        <div style={{display: 'grid'}}>
          <img style={{objectFit: "contain", height: '170px'}} src={require(`../assets/images/45.png`)} />
          <a className="final-submit-btn green-btn-dash" style={{margin: '48px auto auto auto'}} href="/my-games">Start Game</a>
        </div>
      </div>
      {/* <div style={{marginTop: '80px'}}>
        <h4 className="dashboard-tag-left">GET STARTED</h4>
        <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '50px'}}>LET'S <span style={{color: '#268fff'}}>P</span><span style={{color: red}}>L</span><span style={{color: yellow}}>A</span><span style={{color: green}}>Y</span>!</h1>
        <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Grab a device to host, and make sure each player has a device of their own!</p>
        <img style={{objectFit: "contain", height: '200px', width: '100%'}} src={require(`../assets/images/16.png`)} />
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn green-btn-dash" style={{margin: '24px auto', width: '150px', paddingLeft: '32px', paddingRight: '32px'}} href="/my-games">Start Game</a>
        </div>
      </div> */}
      {/* <div style={{marginTop: '80px'}}>
        <h4 className="dashboard-tag-center">DISCOVER SOMETHING NEW</h4>
        <h1 className="dashboard-section-title" style={{fontSize: '50px'}}>Do you need <span style={{color: '#268fff'}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: '#268fff'}}>L</span><span style={{color: red}}>K</span>?</h1>
        <p className="dashboard-section-subtitle">With just 10 questions you can see where you and your team's communication could benefit from using Pro Talk with your team</p>
        <img style={{objectFit: "contain", height: '200px', width: '100%'}} src={require(`../assets/images/16.png`)} />
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn green-btn-dash" style={{margin: '24px auto', width: '150px', paddingLeft: '32px', paddingRight: '32px'}} href="https://getprotalk.com/survey">Take the survey</a>
        </div>
      </div> */}
    </div>
  );
}
export default Dashboard;