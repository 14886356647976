import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, onSnapshot, doc, updateDoc, deleteField } from "firebase/firestore";
import Overlay from "../resources/Overlay";

import UpgradRequired from "../components/UpgradeRequired";
import HostWaitingRoom from "./HostWaitingRoom";
import HostTutorial from "./HostTutorial";
import HostPlaying from "./HostPlaying";
import HostCreateGame from "./HostCreateGame";
import ConfettiExplosion from "react-confetti-explosion";
import LoadingComponent from "../components/LoadingComponent";

function Host() {
    const [user, loading, error] = useAuthState(auth);
    const [accountType, setAccountType] = useState("");
    const [roomKey, setRoomKey] = useState(null);
    const [activeHost, setActiveHost] = useState(null);
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isExploding, setIsExploding] = useState(false);

    const navigate = useNavigate();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const roomCode = urlSearchParams.get("code") ?? "";

    const [solutionCards, setSolutionCards] = useState([]);
    const [challengeCards, setChallengeCards] = useState([]);

    const [remainingChallengeCards, setRemainingChallengeCards] = useState([]);
    const [remainingSolutionCards, setRemainingSolutionCards] = useState([]);

    // Colors
    const red = "#C65447";
    const redBackground = "#F4DDDA";
    const green = "#509E6F";
    const greenBackground = "#DCECE2";
    const blue = "#1961A8";
    const blueBackground = "#D1DFEE";
    const yellow = "#FFC337";
    const orange = "#ED6742";
    const gold = "#F0B52C";
    const goldBackground = "#FCF0D5";

    const fetchAccountType = async () => {
        try {
            if (user == null) {
            setAccountType("basic");
            console.log("No user data");
            return;
            }
            const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
            var hasSubscription = false;
            var productId = "";
            querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const status = doc.data().status;
            if (!hasSubscription) {
                if (status == "trialing" || status == "active") {
                hasSubscription = true;
                productId = doc.data().product.id;
                }
            }
            });
            if (hasSubscription) {
            if (productId === "prod_PbBu30DBX1c1j0") {
                setAccountType("premium");
            } else if (productId === "prod_POZmffO88V5Kwe") {
                setAccountType("plus");
            }
            } else {
            setAccountType("basic");
            }
        } catch (err) {
            console.error(err);
            console.log("An error occured while fetching user data");
        }
    };

    const fetchCurrentGame = async () => {
        try {
            console.log(user?.uid)
            if (roomCode != "") {
                const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true), where("code", "", roomCode));
                const doc = await getDocs(q);
                setRoomKey(doc.docs[0].id);
                const data = doc.docs[0].data();
                console.log(data)
                setActiveHost(data);
            } else {
                const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true));
                const doc = await getDocs(q);
                setRoomKey(doc.docs[0].id);
                const data = doc.docs[0].data();
                console.log(data)
                setActiveHost(data);
            }
        } catch (err) {
            console.log("FAILED TO GET ACTIVE HOST")
            console.error(err);
        }
    };

    function updateRemainingCards() {
        if (roomKey != "") {
            console.log('Update Remaining Cards');
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                remainingChallenges: remainingChallengeCards,
                remainingSolutions: remainingSolutionCards
            });
        }
    }

    function updatePlayers(players) {
        if (roomKey != "") {
            console.log(`Update Player Cards for Players: ${players}`);
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                players: players
            });
        }
    };

    function kickPlayer(id) {
        if (roomKey != "") {
            const validPlayers = activeHost?.players.filter((player) => {
                return player.id != id;
            });
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                players: validPlayers
            });
        }
    };

    function getSlotCard(index) {
        const indexes = [activeHost?.slot1, activeHost?.slot2, activeHost?.slot3, activeHost?.slot4, activeHost?.slot5, activeHost?.slot6, activeHost?.slot7, activeHost?.slot8, activeHost?.slot9, activeHost?.slot10]
        if (activeHost != null) {
            if (indexes[index] != null) {
                if (indexes[index] > 36) {
                    return solutionCards.filter((card) => {
                        return indexes[index] == card.data().id;
                    })[0]?.data();
                } else {
                    return challengeCards.filter((card) => {
                        return indexes[index] == card.data().id;
                    })[0]?.data();
                }
            }
        }
        return null;
    }

    const openOverlay = () => {
        setOverlayOpen(true);
    };

    const closeOverlay = () => {
        setOverlayOpen(false);
    };

    const fetchSolutions = async () => {
        try {
            console.log("solutions");
            const q = query(collection(db, "solutions"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            console.log(myCards);
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setSolutionCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };
  
    const fetchChallenges = async () => {
        try {
            const q = query(collection(db, "challenges"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setChallengeCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };

    function calculateNewProjectAmount(oldAmount) {
        if (oldAmount == 10000) {
            return 25000;
        } else if (oldAmount == 25000) {
            return 50000;
        } else if (oldAmount == 50000) {
            return 100000;
        } else if (oldAmount == 100000) {
            return 250000;
        } else if (oldAmount == 250000) {
            return 500000;
        } else if (oldAmount == 500000) {
            return 750000;
        } else if (oldAmount == 750000) {
            return 1000000;
        } else {
            return 10000;
        }
    };

    function calculateNewRequirement(projectAmount) {
        if (projectAmount == 10000) {
            return 2;
        } else if (projectAmount == 25000) {
            return 3;
        } else if (projectAmount == 50000) {
            return 3;
        } else if (projectAmount == 100000) {
            return 3;
        } else if (projectAmount == 250000) {
            return 4;
        } else if (projectAmount == 500000) {
            return 4;
        } else if (projectAmount == 1000000) {
            return 5;
        }
        return 2;
    };

    function displayProjectCompleteIfNeeded(roomKey, host) {
        // Reset project if all slots are satisfied
        console.log('displayProjectCompleteIfNeeded');
        if (host.currentState === 'nextProject') {
            return;
        }
        const roomRef = doc(db, "hosts", roomKey);
        var shouldNotifyVIP = false;
        if (host.requirementAmount == 2 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT PROJECT");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 3 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT PROJECT");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 4 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT PROJECT");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 5 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null && host.slot9 != null && host.slot10 != null) {
            console.log("YOU WON THE GAME!");
            shouldNotifyVIP = true;
        }
        if (shouldNotifyVIP) {
            updateDoc(roomRef, {
                currentState: "waitingForVIPToProceed",
                vipNextNeeded: true
            });
        }
    }

    function incrementToTheNextProjectIfNeeded(roomKey, host) {
        // Reset project if all slots are satisfied
        console.log('incrementToTheNextProjectIfNeeded');
        if (host.vipNextNeeded || host.currentState === "waitingForVIPToProceed") {
            console.log('NOT READY YET');
            return;
        }
        try {
            const roomRef = doc(db, "hosts", roomKey);
            if (host.requirementAmount == 2 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null) {
                console.log("WE COMPLETED A PROJECT!")
                setIsExploding(true);
                const addToBank = host.projectAmount;
                const newBank = host.currentBank + addToBank;
                const newProjectAmount = calculateNewProjectAmount(host.projectAmount);
                const newRequirement = calculateNewRequirement(newProjectAmount);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentBank: newBank,
                    projectAmount: newProjectAmount,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 3 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null) {
                console.log("WE COMPLETED A PROJECT!")
                setIsExploding(true);
                const addToBank = host.projectAmount;
                const newBank = host.currentBank + addToBank;
                const newProjectAmount = calculateNewProjectAmount(host.projectAmount);
                const newRequirement = calculateNewRequirement(newProjectAmount);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentBank: newBank,
                    projectAmount: newProjectAmount,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 4 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null) {
                console.log("WE COMPLETED A PROJECT!")
                const addToBank = host.projectAmount;
                const newBank = host.currentBank + addToBank;
                const newProjectAmount = calculateNewProjectAmount(host.projectAmount);
                const newRequirement = calculateNewRequirement(newProjectAmount);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentBank: newBank,
                    projectAmount: newProjectAmount,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    slot7: deleteField(),
                    slot8: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 5 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null && host.slot9 != null && host.slot10 != null) {
                console.log("YOU WON THE GAME!")
                const addToBank = host.projectAmount;
                const newBank = host.currentBank + addToBank;
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentBank: newBank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    slot7: deleteField(),
                    slot8: deleteField(),
                    slot9: deleteField(),
                    slot10: deleteField()
                });
            } 
        } catch {
            console.log('Increment To Next Failed')
        }
    }

    useEffect(() => {
        if (loading) {
            return;
        }
        fetchCurrentGame();
        fetchSolutions();
        fetchChallenges();
        fetchAccountType();
        const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setActiveHost(change.doc.data());
                setRoomKey(change.doc.id);
                displayProjectCompleteIfNeeded(change.doc.id, change.doc.data());
                if (change.doc.data().currentState === "nextProject") {
                    incrementToTheNextProjectIfNeeded(change.doc.id, change.doc.data());
                }
            });
        });
    }, [loading, user]);
    return (
        <div style={{background: 'white', minHeight: '900px'}}>
            {(loading)? (
                <LoadingComponent style={{height: '100px'}}/>
            ) : (
                <div>
                    <div className="dashboard-score-container" style={{background: '#00000000'}}>
                        {(accountType === "plus" || accountType === "premium")?(
                            <div style={{width: '100%'}}>
                                <HostWaitingRoom style={{display: `${activeHost?.currentState === 'waitingRoom' ? '' : 'none'}`}} activeHost={activeHost} onClick={(playerId) => {
                                    kickPlayer(playerId);
                                }} />
                                <HostTutorial style={{display: `${activeHost?.currentState === 'tutorial' ? '' : 'none'}`}} activeHost={activeHost} />
                                <HostPlaying style={{display: `${(activeHost?.currentState === 'playing' || activeHost?.currentState === 'waitingForVIPToProceed' || activeHost?.currentState === 'nextProject') ? '' : 'none'}`}} activeHost={activeHost} slot1={getSlotCard(0)} slot2={getSlotCard(1)} slot3={getSlotCard(2)} slot4={getSlotCard(3)} slot5={getSlotCard(4)} slot6={getSlotCard(5)} slot7={getSlotCard(6)} slot8={getSlotCard(7)} slot9={getSlotCard(8)} slot10={getSlotCard(9)} />
                                <HostCreateGame style={{display: `${(activeHost?.currentState == null || activeHost?.currentState === 'ended') ? '' : 'none'}`}} onClick={() => { }}
                            />
                            </div>
                        ) : (
                            <div style={{margin: 'auto'}}>
                            {(accountType === "")? (
                                <div>
                                    <LoadingComponent style={{width: '100px'}}/>
                                </div>
                            ) : (
                                <UpgradRequired
                                    title="Upgrade to host virtual Pro Talk"
                                    onClick={() => {
                                        if (user != null) {
                                            navigate('/pricing')
                                        } else {
                                            navigate('/account')
                                        }
                                        
                                    }}
                                />
                            )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            
            {isOverlayOpen && (
                    <Overlay onClose={closeOverlay}>
                    <div className="scrollable-content">
                        {/* Your scrollable content goes here */}
                        <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                        <h2 style={{textAlign: 'center', color: gold, background: goldBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>GOLD</h2>
                        <p>Unlimited access to Pro Talk content including cards and book. Gain access to all Pro Talk team survey results and insights. Create an unlimited number of survey groups and view a full history of any survey you have taken before. Also gain access to all future updates and content including Pro Talk Kids™, Pro Talk Sales™ and more!</p>
                        <a className="final-submit-btn green-btn" href="https://protalkgame.com/products/pro-talk-gold" target="blank">Upgrade Now</a>
                    </div>
                    </Overlay>
            )}
            {isExploding && <ConfettiExplosion style={{width: '100%', height: '100%'}} />}
        </div>
    );
}

export default Host;