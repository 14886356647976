import React, { useState, useEffect } from "react";
import "../App.css";
import "../dashboard/Dashboard.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";

const PricingPage = () => {
    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) {
            return;
        }
    }, [loading, user]);

    return (
        <StripePricingTable user={user}/>
    );
};

export default PricingPage;

const StripePricingTable = ({ user }) => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
   
   
      return () => {
        document.body.removeChild(script);
      };
    }, []);
      
    return React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1OmMg7DmRtME6qTqrRZemzrM",
        "publishable-key": "pk_live_51OZmNwDmRtME6qTqMetVAuPuyiJh30Ms1wKJAsfbcdZ0LKeaYpNNj1QZEOyFa48TYvZBJu65VDhRge15nlVTXa9g00JsdvwLfT",
        "client-email": user?.email ?? "",
    });
   };