import { NavLink } from 'react-router-dom';
import { ReactComponent as Brand } from '../../assets/images/46.png';
import { useState } from 'react';
import "./Navbar.css";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {

  const [showNavbar, setShowNavbar] = useState(false)

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  }

  const hideNavbar = () => {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <a className='segment-item' style={{display:'flex', alignItems: 'center', fontSize: '28px', color: 'black', textDecoration: 'none'}} href='/'><img id="cardimg" style={{objectFit: "contain", height: "40px", marginRight: '6px'}} src={require(`../../assets/images/46.png`)} />PROTALK.<span style={{color: blue}}>G</span><span style={{color: red}}>A</span><span style={{color: yellow}}>M</span><span style={{color: green}}>E</span></a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar} style={{paddingRight: '24px'}}>
          <FaBars />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`} style={{background: '#ffffff'}}>
          <ul>
            <li>
              <NavLink className={'nav-item'} to="/" onClick={() => {hideNavbar()}}>HOME</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/my-games" onClick={() => {hideNavbar()}}>MY GAMES</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://getprotalk.com/resources" onClick={() => {hideNavbar()}}>RESOURCES</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://getprotalk.com/get-started" onClick={() => {hideNavbar()}}>HOW TO PLAY</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} style={{marginRight: '12px'}} to="/account" onClick={() => {hideNavbar()}}>ACCOUNT</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar