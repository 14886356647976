import React from 'react';
import '../resources/Resources.css';

const PlayerPlayCardOverlay = ({ onClose, children }) => {
  return (
    <div className="play-card-overlay">
      <div className="play-card-overlay-content">
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        {children}
      </div>
    </div>
  );
};

export default PlayerPlayCardOverlay;