import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import CountdownTimer from "./CountdownTimer";
import ProjectCard from "../resources/ProjectCard";
import ProTalkCard from "../resources/ProTalkCard";
import ConfettiComponent from "../components/ConfettiComponent";
import Timer from "../components/Timer";

class HostPlaying extends React.Component {
    render() {
        const { activeHost, onClick, style, currentTime, slot1, slot2, slot3, slot4, slot5, slot6, slot7, slot8, slot9, slot10 } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const animationData = require(`../assets/images/host-waiting.json`);

        const allSlots = [slot1, slot2, slot3, slot4, slot5, slot6, slot7, slot8, slot9, slot10];

        function getAvailableSlots() {
            if (activeHost?.requirementAmount === 2) {
                return [0, 1, 2, 3];
            }
            if (activeHost?.requirementAmount === 3) {
                return [0, 1, 2, 3, 4, 5];
            }
            if (activeHost?.requirementAmount === 4) {
                return [0, 1, 2, 3, 4, 5, 6, 7];
            }
            if (activeHost?.requirementAmount === 5) {
                return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            }
            return [0, 1];
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return (
            <div style={style}>
                {/* <div style={{position: 'absolute', top: '10%', right: '10%'}}><Timer /></div> */}
                <h2 className="survey-title" style={{textAlign: 'center', background: '#33333320', padding: '8px', borderRadius: '10px'}}>{`CURRENT BANK: $${numberWithCommas(activeHost?.currentBank ?? 0)}`}</h2>
                <ProjectCard amount={numberWithCommas(activeHost?.projectAmount ?? 1000)} numberOfCardsRequired={activeHost?.requirementAmount ?? 1} />
                {(activeHost?.currentState == "waitingForVIPToProceed")? (
                    <div>
                        <h1 className="survey-title" style={{textAlign: 'center'}}>YOU COMPLETED THE PROJECT! 🎉</h1>
                        <h3 className="score-text" style={{textAlign: 'center', marginBottom: '48px', color: 'gray'}}>Discuss what you learned while completing this project! The VIP can tap 'Continue' to start the next project.</h3>
                    </div>
                ) : (
                    <div style={{display: 'flex', margin: '16px auto', width: 'fit-content', alignItems: 'center', background: `${activeHost?.currentPlayer?.color ?? '#000000'}20`, borderRadius: '10px', padding: '0px 24px'}}>
                        <img style={{objectFit: "contain", height: '60px', margin: '0 auto', display: 'block'}} src={require(`../assets/images/${activeHost?.currentPlayer?.avatar ?? 46}.png`)} />
                        <h1 className="survey-title" style={{textAlign: 'center', marginLeft: '8px'}}><span style={{color: `${activeHost?.currentPlayer?.color ?? '#000000'}`}}>{activeHost?.currentPlayer?.name ?? 'KLAY'}'S</span> TURN</h1>
                    </div>
                )}
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '24px'}}>
                    <div style={{display: 'flex'}}>
                        <ProTalkCard 
                            card={allSlots[0]}
                            slotNumber={(1)}
                            isOverlayed={false}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                        <ProTalkCard 
                            card={allSlots[1]}
                            slotNumber={(2)}
                            isOverlayed={true}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                        <ProTalkCard 
                            card={allSlots[2]}
                            slotNumber={(3)}
                            isOverlayed={false}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                        <ProTalkCard 
                            card={allSlots[3]}
                            slotNumber={(4)}
                            isOverlayed={true}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '100px'}}>
                    <div style={{display: 'flex'}}>
                        {(activeHost?.requirementAmount >= 3)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[4]}
                                slotNumber={(5)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[5]}
                                    slotNumber={(6)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                        {(activeHost?.requirementAmount >= 4)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[6]}
                                slotNumber={(6)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[7]}
                                    slotNumber={(8)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '100px'}}>
                    <div style={{display: 'flex'}}>
                        {(activeHost?.requirementAmount >= 5)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[8]}
                                slotNumber={(9)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[9]}
                                    slotNumber={(10)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
  
export default HostPlaying;