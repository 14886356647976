import React from "react";
import Lottie from "lottie-react";

class ConfettiComponent extends React.Component {  
    render() {
        const { style } = this.props;

        const loadingAnimationData = require(`../assets/images/confetti-1.json`);

        return (
            <Lottie style={style} animationData={loadingAnimationData} />
        );
    }
  }
  
export default ConfettiComponent;