import React, { useRef, useEffect, useState } from "react";
import "../App.css";

class HostCreateGame extends React.Component {  
    render() {
        const { style, onClick } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        return (
            <div style={style}>
                <div style={{display: 'flex', height: '100px', marginTop: '32px'}}>
                    <img style={{objectFit: "contain", margin: '0 0 0 auto'}} src={require(`../assets/images/46.png`)} />
                    <img style={{objectFit: "contain", margin: '0 auto 0 0'}} src={require(`../assets/images/5.png`)} />
                </div>
                <h1 className="survey-title" style={{textAlign: 'center', fontSize: '54px', marginTop: '0px', marginBottom: '0px'}}><b><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span><span style={{color: green}}> T</span><span style={{color: blue}}><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></span></b></h1>
                <a className="final-submit-btn green-btn-dash" href="/create" style={{textAlign: 'center', maxWidth: '300px'}}>Create New Game</a>
            </div>
        );
    }
  }
  
  export default HostCreateGame;