import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import { FaMobileAlt, FaMinusCircle } from "react-icons/fa";
import Lottie from "lottie-react";
import LoadingComponent from "../components/LoadingComponent";

class HostWaitingRoom extends React.Component {  
    render() {
        const { activeHost, onClick, style } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const animationData = require(`../assets/images/host-waiting.json`);

        return (
            <div style={style}>
                <h2 className="survey-title" style={{textAlign: 'center'}}>JOIN ON YOUR <FaMobileAlt/> AT: <b><span style={{color: blue}}>PROTALK</span><span style={{color: blue}}>.GAME</span></b></h2>
                <p style={{textAlign: 'center', fontSize: '18px'}}><b>your room code is</b></p>
                <h1 className="survey-title" style={{textAlign: 'center', fontSize: '100px', margin: '0'}}><b>{activeHost?.code}</b></h1>
                <div style={{display: 'flex', height: '100px', marginTop: '32px'}}>
                    <img style={{objectFit: "contain", margin: '0 0 0 auto'}} src={require(`../assets/images/46.png`)} />
                    <img style={{objectFit: "contain", margin: '0 auto 0 0'}} src={require(`../assets/images/5.png`)} />
                </div>
                <h1 className="survey-title" style={{textAlign: 'center', fontSize: '54px', marginTop: '0px', marginBottom: '0px'}}><b><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span><span style={{color: green}}> T</span><span style={{color: blue}}><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></span></b></h1>
                <LoadingComponent style={{height: '80px'}} />
                <p style={{textAlign: 'center', fontSize: '16px'}}>make sure you see all players on the screen below before you begin!</p>
                <div style={{width: '80%', margin: 'auto'}}>
                    {/* <p className="survey-title" style={{textAlign: 'textStart', fontSize: '18px'}}></p> */}
                    <div style={{display: 'flex', marginTop: '64px'}}>
                        {activeHost?.players?.map(player => (
                            <div key={player.id} style={{width: '25%'}}>
                                <img style={{objectFit: "contain", height: '120px', margin: '0 auto', display: 'block'}} src={require(`../assets/images/${player.avatar}.png`)} />
                                <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '0px'}}><b>{player.name}</b></h2>
                                {(player.isVIP)? (
                                    <div>
                                        <p className="survey-title" style={{padding: '3px 16px', color: gold, background: goldBackground, width: 'fit-content', margin: '0 auto', borderRadius: '3px'}}>VIP</p>
                                    </div>
                                ) : (
                                    <div id="mouse_over">
                                        <a className="final-submit-btn red-btn" style={{padding: '6px', borderRadius: '20px'}} onClick={() => {
                                            onClick(player.id);
                                        }}><FaMinusCircle style={{height: '12px', width: '12px'}}/> Kick player</a>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
  
export default HostWaitingRoom;