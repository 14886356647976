import React, { useRef, useEffect, useState } from "react";
import "./Resources.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useSprings, animated, to } from 'react-spring'
import LockOverlay from "./LockOverlay";

class ProjectCard extends React.Component {  
    render() {
      const { amount, numberOfCardsRequired, rotation, left, right } = this.props;

      const red = "#C65447";
      const green = "#509E6F";
      const orange = "#ED6742";

      return (
        <div className="project-card" style={{border: "10px", borderColor: orange, borderStyle: "solid", margin: '0px auto', left: `${left}px`, right: `${right}px`, height: '300px', display: 'grid', rotate: `${rotation}deg`}}>
          <p className="card-title" style={{color: orange, fontWeight: '600'}}>PROJECT</p>
          <p className="card-name" style={{fontSize: 'xxx-large', fontWeight: '800', margin: 'auto 0px'}}>{`$${amount}`}</p>
          <p style={{fontSize: "14px", textAlign: "center", height: "auto", marginTop: "auto", paddingLeft: "4px", paddingRight: "4px", color: "gray", fontWeight: 'bold'}}><span style={{color: red}}>{`${numberOfCardsRequired} Challenges`}</span> + <span style={{color: green}}>{`${numberOfCardsRequired} Solutions`}</span> <span style={{fontWeight: 'normal'}}>completes this project</span></p>
        </div>
      );
    }
  }
  
  export default ProjectCard;