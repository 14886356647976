import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import Overlay from "../resources/Overlay";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import Lottie from "lottie-react";
import LoadingComponent from "../components/LoadingComponent";

function MyGames() {
  const [user, loading, error] = useAuthState(auth);
  const [accountType, setAccountType] = useState("");
  const [allowAudience, setAllowAudience] = useState(false);
  const [activeGames, setActiveGames] = useState([]);
  const [displayHints, setDisplayHints] = useState(false);
  const navigate = useNavigate();
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [deckType, setDeckType] = useState("basic");
  const [gamePlayType, setGamePlayType] = useState("standard");

  // Colors
  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const animationData = require(`../assets/images/host-waiting.json`);

  function generateRandomCode(length) {
    var charset = "ABCDEFGHJKLMNPQRSTUVWXYZ",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    // Make sure this doesn't exist in firebase currently
    const q = query(collection(db, "hosts"), where("code", "==", `${retVal}`));
    const potentialDocs = getDocs(q);
    if ((potentialDocs?.docs?.length ?? 0) > 0) {
      return generateRandomCode(length);
    }
    return retVal;
  }

const fetchAccountType = async () => {
  try {
    if (user == null) {
      setAccountType("basic");
      console.log("No user data");
      return;
    }
    const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
    var hasSubscription = false;
    var productId = "";
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const status = doc.data().status;
      if (!hasSubscription) {
        if (status == "trialing" || status == "active") {
          console.log(`STATUS: ${status}`)
          hasSubscription = true;
          productId = doc.data().product.id;
        }
      }
    });
    if (hasSubscription) {
      if (productId == "prod_PbBu30DBX1c1j0") {
        setAccountType("premium");
      } else if (productId == "prod_POZmffO88V5Kwe") {
        setAccountType("plus");
      }
    } else {
      console.log("No subscription");
      setAccountType("basic");
    }
  } catch (err) {
    console.error(err);
    console.log("An error occured while fetching user data");
  }
};

  const fetchActiveGames = async () => {
    const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid ?? ""), where("active", "==", true));
    const allRoomQuery = await getDocs(q);
    console.log(`FETCHED ACTIVE ROOMS: ${allRoomQuery.docs}`);
    setActiveGames(allRoomQuery.docs)
  };

  const attemptToStartGame = async () => {
    console.log(user?.uid)
    const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid));
    const allRoomQuery = await getDocs(q);
    console.log(`ALL ROOMS: ${allRoomQuery.docs}`);
    const activeRooms = allRoomQuery.docs.filter((item => {
      return item.data().active == true
    }))
    console.log(`ACTIVE ROOMS: ${activeRooms}, COUNT ${activeRooms.length}`);
    if (activeRooms.length >= 1) {
      alert("Sorry, your account only supports one game of Pro Talk at a time.");
      return;
    } else {
      const collectionRef = collection(db, 'hosts');
      const code = generateRandomCode(4);
      const newRoomData = {
        ownerId: user?.uid ?? "",
        code: `${code}`,
        allowHints: displayHints,
        allowAudience: allowAudience,
        currentState: "waitingRoom",
        currentBank: 0,
        active: true
      };
      addDoc(collectionRef, newRoomData).then(() => {
        console.log('Document added successfully!');
        navigate(`/host?code=${code}`)
      }).catch((error) => {
      console.log('Error adding document:', error);
      });
    }
  };

  function endGame(id) {
    console.log(`ID: ${id}`);
    const roomRef = doc(db, "hosts", id);
    updateDoc(roomRef, {
      active: false
    });
    fetchActiveGames();
  }

  const openOverlay = () => {
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
  };

  function deckTypeSelected(type) {
    if (type == "kids") {
      alert("Playing with Pro Talk Kids is coming soon!");
    } else {
      setDeckType(type);
    }
  };

  function gamePlayTypeSelected(type) {
    setGamePlayType(type)
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchAccountType();
    fetchActiveGames();
  }, [user, loading]);
  return (
    <div className="full-page-container">
      <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: '16px'}}>
              <h4 className="dashboard-tag-left">GET STARTED</h4>
              <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '48px'}}>VIRTUAL GAME PLAY</h1>
              <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Playing Pro Talk with your team is now easier than ever! Start a new virtual game with your team. The best way </p>
          </div>
          <div style={{display: 'grid'}}>
              <img style={{objectFit: "contain", width: '150px'}} src={require(`../assets/images/38.png`)} />
              {/* <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto', width: '60%'}} href="https://protalkgame.com/pages/contact" target="blank">Learn How</a> */}
          </div>
          </div>
      {(loading)? (
        <Lottie style={{height: '80px'}} animationData={animationData} />
      ) : (
        <div>
          {(activeGames.length > 0)? (
            <div>
              <h1 className="survey-title" style={{marginTop: '24px', color: 'white'}}>IN PROGRESS GAMES</h1>
              <div>
              {activeGames.map(game => (
                <div style={{display: 'flex'}}>
                    <p className="score-text" style={{color: '#ffffff'}}>ROOM CODE: <b style={{fontSize: '24px'}}>{game.data().code}</b></p>
                    <a className="final-submit-btn blue-btn-dash" style={{cursor: 'pointer', margin: 'auto 16px auto auto', width: 'auto'}} onClick={() => {
                      navigate(`/host?code=${game.data().code}`);
                    }}>View Room</a>
                    <a className="final-submit-btn red-btn-dash" style={{cursor: 'pointer', width: 'auto', margin: 'auto 0'}} onClick={() => {
                      endGame(game.id)
                    }}>End game</a>
              </div>
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <h1 className="survey-title" style={{marginTop: '48px', color: 'white'}}>HOST A NEW GAME</h1>
          <div>
            <div className="dashboard-score-container" style={{background: '#00000000'}}>
                {(accountType == "plus" || accountType == "premium")?
                    (
                    <div style={{width: '100%'}}>
                      <p className="score-text" style={{marginBottom: '6px'}}>GAMEPLAY OPTIONS</p>
                      <p className="score-text" style={{color: '#ffffff', fontWeight: '700'}}>Deck Type</p>
                      <div className="segment-div" style={{marginTop: '4px', marginLeft: '0px', marginRight: '0px'}}>
                      <a className={`segment-item ${deckType === "basic" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px'}} onClick={() => {deckTypeSelected("basic")}}>PRO TALK - BASIC</a>
                      <a className={`segment-item ${deckType === "kids" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {deckTypeSelected("kids")}}>PRO TALK - KIDS</a>
                      {/* <a className={`segment-item ${deckType === "sales" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {deckTypeSelected("sales")}}>PRO TALK - SALES</a> */}
                      </div>
                      <p className="score-text" style={{color: '#ffffff', fontWeight: '700'}}>Game Play</p>
                      <div className="segment-div" style={{marginTop: '4px', marginLeft: '0px', marginRight: '0px', marginBottom: '0px'}}>
                      <a className={`segment-item ${gamePlayType === "standard" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px'}} onClick={() => {gamePlayTypeSelected("standard")}}>STANDARD</a>
                      <a className={`segment-item ${gamePlayType === "freeplay" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {gamePlayTypeSelected("freeplay")}}>FREE PLAY</a>
                      </div>
                      <p className="score-text" style={{marginTop: '0px'}}>{gamePlayType === "standard" ? 'Standard Pro Talk play takes just 10 minutes to play. Each player takes turns playing either a solution or challenge card to complete the current project.': 'Free Play allows a more fluid type of game play for advanced teams. Players can play cards in any order they would like to complete projects.'}</p>
                      <p className="score-text" style={{marginBottom: '6px', marginTop: '48px'}}>PREMIUM OPTIONS</p>
                      <div style={{display: 'flex'}}>
                        <p className="score-text" style={{color: '#ffffff', fontWeight: '700'}}>Advanced Hints</p>
                        <a style={{cursor: 'pointer', margin: 'auto 0 auto auto'}} onClick={() => {
                          if (accountType == 'premium') {
                            setDisplayHints(!displayHints);
                          } else {
                            openOverlay();
                          }
                        }}>
                          <FaCheckSquare style={{height: '35px', width: '35px', color: 'white', display: `${displayHints ? '' : 'none'}`}}/>
                          <FaSquare style={{height: '35px', width: '35px', color: 'gray', display: `${displayHints ? 'none' : ''}`}}/>
                        </a>
                      </div>
                      <p className="score-text" style={{marginTop: '0px', marginBottom: '64px'}}>Advanced hints will help suggest solutions when you are presented with Challenges. This will allow those that aren't familiar with common communication challenges feel more confident in their answers.</p>
                      <a className="final-submit-btn green-btn-dash" style={{textAlign: 'center', marginTop: '24px'}} onClick={() => {
                        attemptToStartGame();
                      }}>Start New Game</a>
                    </div>
                    ) : (
                      <div style={{margin: 'auto'}}>
                        {(accountType == "")? (
                          <LoadingComponent style={{height: '100px', width: '100%'}} />
                        ) : (
                          <a className="final-submit-btn green-btn-dash" href={`${user == null ? '/account' : '/pricing'}`} style={{width: '100%', textAlign: 'center', margin: 'auto 0'}}>{`${user == null ? 'Log in to play Pro Talk virtually' : 'Upgrade your account to play Pro Talk virtually'}`}</a>
                        )}
                      </div>
                    )}
            </div>
          </div>
        </div>
      )}
      {isOverlayOpen && (
            <Overlay onClose={closeOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                <h2 style={{textAlign: 'center', color: green, background: greenBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>PREMIUM</h2>
                <p>Gain access to all premium features while playing virtual Pro Talk!</p>
                <a className="final-submit-btn green-btn" href="/pricing" target="blank">Upgrade to Premium</a>
              </div>
            </Overlay>
      )}
    </div>
  );
}

export default MyGames;