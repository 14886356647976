import React, { useRef, useEffect, useState } from "react";
import "../App.css";

class HostTutorial extends React.Component {  
    render() {
        const { activeHost, onClick, style } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const animationData = require(`../assets/images/host-waiting.json`);

        return (
            <div style={style}>
                <h2 className="survey-title" style={{textAlign: 'center'}}>LET'S GET STARTED</h2>
                <video width="750" height="500" style={{margin: '0 auto', display: 'block'}}>
                    <source src={'https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayProTalk.mp4?alt=media&token=776fe4d3-8989-4412-aa98-b3aaf19e6433'} />
                </video>
                <p style={{textAlign: 'center'}}>Already know how to play? The VIP can skip at any time</p>
            </div>
        );
    }
}
  
export default HostTutorial;