import React from "react";
import "./Resources.css";

class PlayerCard extends React.Component {  
    render() {
        const { card, onClick, style } = this.props;
    
        // Colors
        const red = "#C65447";
        const green = "#509E6F";
    
        function getCardType() {
          if (card?.id < 37) {
              return "CHALLENGE";
          } else {
              return "SOLUTION";
          }
        };
    
        function getCardColor() {
          if (card.id < 37) {
              return red;
          } else {
              return green;
          }
        }; 

      return (
        <div className="player-card" style={{style, border: "8px", borderColor: `${getCardColor()}`, borderStyle: "solid", height: '300px', display: 'grid'}} onClick={onClick}>
          <p className="player-card-title" style={{color: `${getCardColor()}`}}>{getCardType()}</p>
          <p className="player-card-name">{card.name}</p>
          <img id="cardimg" style={{margin: 'auto', objectFit: "contain", maxWidth: '90%', height: "80px"}} src={require(`../assets/images/${card.id}.png`)} />
          <p className="player-card-description">{card.detail}</p>
        </div>
      );
    }
  }
  
  export default PlayerCard;